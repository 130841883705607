import { Component, Input } from '@angular/core';
import config from '@config';
import { IconPath } from '@shared/models/icon-path.enum';
import { TenantResource } from '@shared/models/tenant.model';
import Utils from '@shared/providers/utils';

interface ExtendedTenantResource extends TenantResource {
  icon: IconPath;
}

type Modifier = 'margin-y';

@Component({
  selector: 'app-downloadable-resource',
  templateUrl: './downloadable-resource.component.html',
  styleUrls: ['./downloadable-resource.component.scss'],
})
export class DownloadableResourceComponent {
  static readonly REMOTE_BASE_ADDRESS = 's3.amazonaws.com';

  @Input() set resource(resource: TenantResource) {
    this._resource = this.turnResourceIntoExtendedResource(resource);
  }
  @Input() set modifiers(modifiers: Modifier[]) {
    this.onModifiers(modifiers);
  }

  _modifiers = '';
  _resource: ExtendedTenantResource;

  onModifiers(modifiers: Modifier[]): void {
    this._modifiers = Utils.getModifiedStringArrayAsString(modifiers, 'downloadable-resource--', 'prepend');
  }

  turnResourceIntoExtendedResource(resource: TenantResource): ExtendedTenantResource {
    const _resource = Utils.copyObject(resource) as ExtendedTenantResource;
    _resource.file_name = `https://${DownloadableResourceComponent.REMOTE_BASE_ADDRESS}/${config.downloadableBucket}/${resource.file_name}`;
    _resource.icon = this.getIconPathFromFileName(_resource.file_name);
    return _resource;
  }

  getIconPathFromFileName(fileName: string): IconPath {
    const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
    switch (fileExtension) {
      case 'docx':
        return IconPath.Docx;
      case 'pptx':
        return IconPath.Pptx;
      default:
        return IconPath.Pdf;
    }
  }
}
